import { stringify } from "query-string";

import {
  FontIcon,
  FontWeights,
  ILinkStyles,
  ITextStyles,
  Link,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { SystemNoticeType } from "@shared-types/practice/system-notice-type.type.ts";
import { SystemNotice } from "@shared-types/practice/system-notice.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { PatientCardIds } from "../types/patient-card-ids.enum.ts";
import { SystemNoticeTypeLabel } from "./SystemNotice.types.ts";

interface SystemNoticeTableRowProps {
  systemNotice: SystemNotice;
  patientId: string;
}

const linkStyles: ILinkStyles = {
  root: {
    flexShrink: 0
  }
};

export const SystemNoticeTableRow: React.FC<SystemNoticeTableRowProps> = ({
  systemNotice,
  patientId
}) => {
  const { palette, semanticColors } = useTheme();
  const {
    routing,
    practice: {
      ui: { showEditContact }
    },
    core
  } = useStores();

  const commentStyle: ITextStyles = { root: { wordBreak: "break-word" } };

  const showActions =
    systemNotice.type !== SystemNoticeType.interpreter &&
    core.hasPermissions(Permission.PaymentCreate);

  return (
    <Stack
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: 4 }}
      styles={{ root: { minHeight: 32 } }}
    >
      <FontIcon
        iconName="Warning"
        styles={{ root: { color: semanticColors.severeWarningIcon } }}
      />

      <Stack.Item
        grow
        styles={{
          root: {
            color: palette.themeDarker,
            fontWeight: FontWeights.semibold
          }
        }}
      >
        {SystemNoticeTypeLabel[systemNotice.type]}

        {systemNotice.type === SystemNoticeType.account ? (
          <>
            {systemNotice.owingTotal && (
              <Text styles={commentStyle}>
                {" - Owing "}
                <span style={{ fontWeight: FontWeights.semibold }}>
                  {systemNotice.owingTotal}
                </span>
              </Text>
            )}
            {systemNotice.unallocatedCredit && (
              <Text styles={commentStyle}>
                {" - Credit "}
                <span style={{ fontWeight: FontWeights.semibold }}>
                  {systemNotice.unallocatedCredit}
                </span>
              </Text>
            )}
          </>
        ) : (
          <Text styles={commentStyle}>{` - ${systemNotice.comment}`}</Text>
        )}
      </Stack.Item>
      {showActions && (
        <>
          {systemNotice.type === SystemNoticeType.account && (
            <Link
              styles={linkStyles}
              onClick={() =>
                routing.pushWithFromQuery({
                  pathname: routes.accounts.allocations.new.pattern,
                  search: stringify({ accountId: patientId })
                })
              }
            >
              Pay account
            </Link>
          )}
          {systemNotice.type === SystemNoticeType.cardExpired && (
            <Link
              styles={linkStyles}
              onClick={() =>
                showEditContact(PatientCardIds.patientEntitlements, patientId)
              }
            >
              Update
            </Link>
          )}
        </>
      )}
    </Stack>
  );
};
